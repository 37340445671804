import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { useApp } from '@/core/contexts/app'
import Anchor from '@/components/base/anchor'
import { Column, Row, Container } from '@/components/base/gridview'
import Icon from '@/components/base/icon'
import Image from '@/components/base/image'
import pageService from '@/services/http/wap/page'

import { PanelProps } from './types'
import Button from '@/components/base/button'
import { path } from '@/core/utils'

const Panel: React.FunctionComponent<PanelProps> = ({
  children,
  isOpen,
  logo,
  onClose,
}) => {
  const app = useApp()
  const router = useRouter()
  const template = app.theme.title
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const openLanguage = () => {
    setIsDropdownOpen(!isDropdownOpen)
  }

  const renderLanguageSelection = () => {
    if (app.languages.length > 1) {
      return (
        <div className="asg-header-language-selection">
          <div className="asg-header-language-selection-current">
            <Button onClick={openLanguage}>
              {app.language.substring(0, 2)}
            </Button>
          </div>
          {isDropdownOpen && (
            <div className="asg-header-language-selection-dropdown">
              <div className="asg-header-language-selection-dropdown-list">
                {app.languages.map((language) => (
                  <li key={language.id}>
                    <Button
                      onClick={() => handleLanguageSelection(language.id)}
                    >
                      {language.title}
                    </Button>
                  </li>
                ))}
              </div>
            </div>
          )}
        </div>
      )
    }
  }

  return (
    <div className={`panel panel--${isOpen ? 'show' : 'hide'}`}>
      <Container size={template === 'Ana sayfa teması' ? 'extended' : 'wide'}>
        <Row
          className="row row-align-center row-justify-between"
          style={{ alignItems: 'center' }}
        >
          <Column
            style={{ textAlign: 'left' }}
            sm={{ size: 4 }}
            md={{ size: 4 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
          >
            <Anchor className="panel-logo" href={app.settings.homepage}>
              <Image
                {...(logo?.src ? logo : app.theme.logo)}
                alt={app.page.title}
                height={50}
              />
            </Anchor>
          </Column>
          <Column>
            <div className="asg-header-actions">
              <button className="panel-close-button" onClick={onClose}>
                <Icon name="icon-menu" size="medium" />
              </button>
              {renderLanguageSelection()}
            </div>
          </Column>
        </Row>
      </Container>
      <div style={{ position: 'relative', flex: 1 }}>{children}</div>
    </div>
  )
}

export default Panel
